.HighlightedSection {
  &:not(:last-child) {
    margin-bottom: clamp(16px, calc(20vw / 7.68), 20px);
  }
  padding: clamp(12px, calc(16vw / 7.68), 20px);
  border-radius: 20px;
  background: var(--primary-300, #fff6f9);
  .heading {
    font-size: clamp(1rem, calc(18vw / 7.68), calc(18rem / 16));
    margin-bottom: clamp(8px, calc(12vw / 7.68), 12px);
    color: var(--secondary-500, #726378);
  }
  .paragraph {
    font-size: clamp(calc(14rem / 16), calc(16vw / 7.68), calc(16rem / 16));
  }
  img,
  .video {
    border-radius: 12px;
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    max-width: 418px;
    background: var(--primary-400, #ffd7e4);
  }
  &[data-has-media='true'] {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: clamp(16px, calc(16vw / 7.68), 20px);
    &[data-is-reversed='true'] {
      img,
      .video {
        order: -1;
      }
    }
  }
  @media (max-width: 549px) {
    &[data-has-media='true'] {
      grid-template-columns: 1fr !important;
      img,
      .video {
        order: -1;
      }
    }
  }
}
